import {navigate} from 'gatsby';
import {TranslateUrlsContext} from 'gatsby-plugin-translate-urls';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import kwesforms from 'kwesforms';
import tw, {css, styled, theme} from 'twin.macro';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  generateVoucherNumber,
  getValidTillDate,
  initForm,
} from '../../../utils';
import {Button} from '../../Button';
import FormConsent from '../../FormConsent';
import SVGIcon from '../../SVGIcon';
import CardStyles from './cardsnew';
import GiftCardSuccess from './GiftCardSuccess';
import GiftcardTermsPopup from './GiftcardTermsPopup';

const MAX_AMOUNT = 20000;

export const inputStyles = css`
  ${tw`text-black`}

  &,
  &::placeholder {
    ${tw`font-bold`}
    font-size: 0.93rem;
  }

  &::placeholder {
    color: #adaeae;
  }

  &:focus {
    ${tw`border-primary`}
    outline: none;
    box-shadow: none;
  }
`;

const Wrapper = styled.div`
  padding-top: 150px;
  ${tw`px-10`}
  max-width: 660px;
  margin: 0 auto;

  @media ${props => props.theme.screens.lg} {
    max-width: 90%;
  }
  @media ${props => props.theme.screens.xl} {
    max-width: 1200px;
  }
`;

const Form = styled.form`
  .form_control {
    ${tw`relative block`}

    padding-bottom: 15px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  input[type='checkbox'] {
    accent-color: #1f9638;
    border-radius: 3px;
  }
  input[type='checkbox']:checked {
    background-color: #1f9638;
  }
`;

const FormControl = styled.div`
  ${tw`relative block`}

  padding-bottom: 15px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.recepient-wrapper {
    margin-top: 25px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${props => props.theme.screens.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Label = styled.label`
  color: #adaeae;
  background: ${props => props.theme.colors.defaultBackground};
  display: block;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 127%;

  color: #333435;

  opacity: 0.4;
  margin-bottom: 8px;
`;

const Input = styled.input<{error?: boolean}>`
  ${tw`w-full border border-solid border-100 transition-colors`}

  ${inputStyles}

  border-radius: 11px;
  padding-top: 13px;
  padding-bottom: 13px;

  &:not(:placeholder-shown) ~ label,
  &:focus ~ label {
    font-size: 60%;
    transform: translateY(calc(-100% - 5px));
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const TextArea = styled.textarea<{error?: boolean}>`
  ${tw`w-full border border-solid border-100 rounded-lg resize-none`}

  ${inputStyles}

  height: 80px;

  &:not(:placeholder-shown) ~ label,
  &:focus ~ label {
    font-size: 60%;
    transform: translateY(calc(-100% - 5px));
    padding-left: 10px;
    padding-right: 10px;
  }

  &.special-requests {
    @media ${props => props.theme.screens.xl} {
      /* height: 100px; */
    }
  }
`;

const Title = styled.h1`
  font-weight: bold;
  color: #333435;

  font-size: 30px;
  line-height: 42px;

  @media ${props => props.theme.screens.md} {
    font-size: 45px;
    line-height: 56px;
  }

  @media: ${props => props.theme.screens.lg} {
    font-size: 50px;
    line-height: 64px;
  }
`;

const DeliveryInfo = styled.p`
  font-weight: 500;
  margin-top: 22px;
  margin-bottom: 45px;
  color: #224b45;

  font-size: 15px;
  line-height: 24px;
  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
  }

  a {
    color: #039259;
    text-decoration: underline;
  }
`;

const SectionTitle = styled.h2`
  font-weight: bold;
  color: #1f4741;
  margin-top: 45px;
  margin-bottom: 12px;
  @media ${props => props.theme.screens.lg} {
    margin-bottom: 16px;
  }
  @media (min-width: 1111px) {
    margin-top: 30px;
  }

  font-size: 30px;
  line-height: 40px;

  @media ${props => props.theme.screens.md} {
    font-size: 45px;
    line-height: 56px;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 45px;
    line-height: 56px;
  }
`;

const LightText = styled.p`
  font-weight: 500;
  color: #919191;
  margin-bottom: 25px;

  font-size: 15px;
  line-height: 24px;
  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
  }
`;

const Amount = styled(Button)<{selected: boolean}>`
  /* max-width: 140px; */
  /* height: 50px; */

  border-radius: 10px;
  /* margin-right: 8px; */
  background: white;
  border: 1px solid #d6d3cf;
  font-weight: bold;
  line-height: 140%;
  color: #224b45;

  @media ${props => props.theme.screens.lg} {
    padding: 0px;
  }

  ${({selected}) =>
    selected
      ? `
    background: #1F9638;
    border: none;
    color: white;
  `
      : ``}
`;

const AmountsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > button,
  > input {
    flex-basis: 48%;
    margin-bottom: 10px;
    text-align: center;
  }
  margin-bottom: 25px;

  @media ${props => props.theme.screens.sm} {
    margin-bottom: 0px;
    > button,
    > input {
      flex-basis: 23%;
    }
  }

  @media ${props => props.theme.screens.lg} {
    /* margin-bottom: 25px; */
    > button,
    > input {
      flex-basis: 48%;
    }
  }
  @media (min-width: 1111px) {
    > button,
    > input {
      flex-basis: 23%;
    }
  }
  .kw-field-error-message {
    position: absolute;
    bottom: -5px;
    font-size: 0.85em;

    @media ${props => props.theme.screens.sm} {
      font-size: 0.8em;
      bottom: -10px;
    }
    @media ${props => props.theme.screens.xl} {
      font-size: 0.85em;
    }
    @media ${props => props.theme.screens.xl} {
      font-size: 0.85em;
    }
  }

  ${Amount}, input {
    font-size: 20px;
    /* @media ${props => props.theme.screens.lg} {
      font-size: 16px;
    }
    @media ${props => props.theme.screens.xl} {
      font-size: 20px;
    } */
  }
`;

const TermsAndConditions = styled.div`
  ${tw`flex items-center text-secondary font-medium cursor-pointer  hover:opacity-80 transition-opacity mb-8 md:mb-0 lg:mt-10 xl:mt-0`}
  font-size: 16px;
  line-height: 123%;
  align-items: center;

  .terms-text {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 127%;
    text-decoration-line: underline;
    color: #919191;
    margin-left: 10px;
  }
`;

const FormButtom = styled.div`
  margin-top: 50px;
  max-width: 480px;
`;

const ButtonWrapper = styled.div<{isLoading: boolean}>`
  ${tw`flex w-full justify-between`}

  pointer-events: ${props => (props.isLoading ? 'none' : 'all')};
  opacity: ${props => (props.isLoading ? 0.5 : 1)};
  cursor: ${props => (props.isLoading ? 'no-allowed' : 'auto')};
`;

const FormButton = styled(Button)`
  height: 47px;
  flex-basis: 45%;

  &:disabled {
    background: #eeeeee;
    color: #b9b9b9;

    &:hover {
      opacity: 1;
    }
  }
`;

const FileButton = styled.label`
  ${tw`flex items-center text-secondary font-medium cursor-pointer  hover:opacity-80 transition-opacity`}
  flex-basis: 40%;
  font-size: 16px;
  line-height: 123%;

  input {
    display: none;
  }

  svg {
    margin-right: 11px;
  }
`;

const LeftSection = styled.div`
  max-width: 470px;
  margin: 0 auto;
  @media ${props => props.theme.screens.lg} {
    margin-left: 0px;
    margin-right: 80px;
  }
  @media (min-width: 1111px) {
    margin-right: 100px;
  }
  @media ${props => props.theme.screens.xl} {
    margin-right: 150px;
  }
`;

const RightSection = styled.div``;
const BottomSection = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RecipientSection = styled.div`
  margin-top: 20px;
  max-width: 470px;
  margin: 0 auto;
  @media ${props => props.theme.screens.lg} {
    margin-top: 70px;
  }
  @media ${props => props.theme.screens.xl} {
    margin-top: 70px;
  }
`;

const GiftCardForm: React.FunctionComponent = () => {
  const {locale} = useContext(TranslateUrlsContext);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formSubmittedOnce, setFormSubmittedOnce] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(1000);
  const [shouldSend, setShouldSend] = useState(false);
  const [agree, setAgree] = useState(false);
  const [state, setState] = useState({});
  const [fileText, setFileText] = useState('');
  const [open, setOpen] = useState(false);
  const validTill = getValidTillDate(6);
  const voucherNumber = useMemo(
    () => generateVoucherNumber(8),
    [formSubmitted, formSubmittedOnce],
  );

  const {width} = useWindowSize();
  const lg = theme('screens.lg').replace('px', '');
  const sm = theme('screens.sm').replace('px', '');
  const [cardStyle, setCardStyle] = useState('one-christmas-tree');
  const isMobile = Number(width) < Number(lg);
  const {t} = useTranslation();
  const amounts = [1000, 3000, 5000];

  useEffect(() => {
    const form = document.getElementById('giftCardForm');

    form?.addEventListener('kwSubmitted', () => {
      setFormSubmitted(true);
      setFormSubmittedOnce(true);
      setLoading(false);
      setAmount(1000);
    });
  }, [formSubmitted]);

  useEffect(() => {
    if (formSubmittedOnce && !formSubmitted) {
      initForm();
    }
  }, [formSubmitted]);

  useEffect(() => {
    initForm();
  }, [isMobile]);

  useEffect(() => {
    kwesforms?.clearErrors('giftCardForm', 'recipient-email');
  }, [shouldSend]);

  const handleAttachment = (e: any) => {
    if (e.target.files[0]) {
      setFileText('one_attached_file');
    } else {
      setFileText('');
    }

    setState({...state, [e.target.name]: e.target.files[0]});
  };

  const renderYourContactForm = () => {
    return (
      <div>
        <SectionTitle>{t('your_contact_info')}</SectionTitle>
        <LightText>{t('manager_will_contact')}</LightText>
        <FormControl> 
          <Label>{t('full_name')}</Label>
          <Input
            type="text"
            placeholder={' '}
            name="fullname"
            data-kw-rules="required"
          />
        </FormControl>
        <FormControl>
          <Label>{t('Email')}</Label>
          <Input
            type="email"
            placeholder={' '}
            name="email"
            data-kw-rules="required|email"
          />
        </FormControl>
        <FormControl>
          <Label>{t('phone_number')}</Label>
          <Input
            type="tel"
            placeholder={' '}
            name="phone"
            data-kw-rules="required"
          />
        </FormControl>
        <FormControl>
          <Label>{t('special_requests')}</Label>
          <TextArea
            placeholder={' '}
            name="special-requests"
            className="special-requests"
          />
        </FormControl>
        <FormControl style={{display: 'flex'}} className="recepient-wrapper">
          <input
            name="sendDirectly"
            type="checkbox"
            style={{marginRight: 5}}
            id="sendDirectly"
            checked={shouldSend}
            value={shouldSend ? 'Yes' : 'No'}
            onChange={v => {
              setShouldSend(v.target.checked);
            }}
          />
          <Label htmlFor="sendDirectly">{t('send_directly')}</Label>
        </FormControl>
      </div>
    );
  };
  return (
    <Wrapper>
      <GiftcardTermsPopup open={open} handleClose={() => setOpen(false)} />
      <GiftCardSuccess
        open={formSubmitted}
        handleClose={() => {
          setFormSubmitted(false);
          navigate('/');
        }}
      />

      <Form
        className="kwes-form"
        action="https://kwesforms.com/api/foreign/forms/P0hzJYddAldcqnfSxlxo"
        // @ts-ignore
        enctype="multipart/form-data"
        data-kw-no-reload
        id="giftCardForm"
        data-kw-lang={locale}
        autoComplete="off"
      >
        <FormWrapper>
          <LeftSection>
            <input type="hidden" name="locale" value={locale} />
            <input type="hidden" name="card-style" value={cardStyle} />
            <Title>{t('order_gift_card')}</Title>
            <DeliveryInfo dangerouslySetInnerHTML={{ __html: t('gift_card_description')}} />

            <FormControl>
              <Label>{t('amount_with_currency')}</Label>
              <AmountsWrapper>
                {amounts.map(a => (
                  <Amount
                    type="button"
                    key={a + ''}
                    selected={amount === a}
                    onClick={() => {
                      kwesforms?.clearErrors('giftCardForm', 'amount');
                      setAmount(a);
                    }}
                  >
                    {a}
                  </Amount>
                ))}
                <Input
                  type="number"
                  placeholder={' '}
                  name="amount"
                  step={500}
                  value={amount}
                  onChange={e => {
                    const newAmount = Number(e.target.value);
                    setAmount(newAmount);
                  }}
                  min={amounts[0]}
                  max={MAX_AMOUNT}
                  data-kw-rules="numeric|between:1000,20000"
                  // data-kw-rules="required|numeric|between:1100,20000"
                />
              </AmountsWrapper>
            </FormControl>

            {isMobile && (
              <>
                <SectionTitle style={{marginBottom: 22}}>
                  {t('choose_card_design')}
                </SectionTitle>
                <CardStyles
                  amount={amount}
                  cardStyle={cardStyle}
                  setCardStyle={setCardStyle}
                  validTill={validTill}
                  voucherNumber={voucherNumber}
                />
              </>
            )}
            {renderYourContactForm()}
          </LeftSection>
          <RightSection>
            {!isMobile && (
              <>
                <Title style={{marginBottom: 22}}>
                  {t('choose_card_design')}
                </Title>
                <CardStyles
                  amount={amount}
                  cardStyle={cardStyle}
                  setCardStyle={setCardStyle}
                  validTill={validTill}
                  voucherNumber={voucherNumber}
                />
              </>
            )}
            <RecipientSection>
              <div
                kw-show="fields.sendDirectly == true"
                className="form_control"
              >
                <Label>{t('recipient_name')}</Label>
                <Input
                  type="text"
                  placeholder=" "
                  name="recipient-name"
                  // data-kw-rules="required"
                />
              </div>
              <div
                kw-show="fields.sendDirectly == true"
                className="form_control"
              >
                <Label>{t('recipient_email')}</Label>
                <Input
                  type="email"
                  placeholder={' '}
                  name="recipient-email"
                  data-kw-rules="email"
                  // data-kw-rules="required_if:send,true|email"
                />
              </div>
              <div
                kw-show="fields.sendDirectly == true"
                className="form_control"
              >
                <Label>{t('recipient_phone')}</Label>
                <Input
                  type="tel"
                  placeholder={' '}
                  name="recipient-phone"
                  // data-kw-rules="required"
                />
              </div>
              <div
                kw-show="fields.sendDirectly == true"
                className="form_control"
              >
                <Label>{t('your_message_optional')}</Label>
                <TextArea
                  placeholder={' '}
                  name="message"
                  // onChange={e => setMessage(e.target.value)}
                />
              </div>
            </RecipientSection>
          </RightSection>
        </FormWrapper>
        <input type="hidden" name="valid-till" value={validTill} />
        <input type="hidden" name="voucher-number" value={voucherNumber} />
        <BottomSection>
          <div>
            <TermsAndConditions>
              <input
                type="checkbox"
                className="terms-checkbox"
                checked={agree}
                name="agree-terms"
                value={agree ? 'Yes' : 'No'}
                onChange={e => setAgree(e.target.checked)}
              />
              <div className="terms-text" onClick={() => setOpen(true)}>
                {t('gift_terms')}
              </div>
            </TermsAndConditions>
            <FormButtom>
              <ButtonWrapper isLoading={loading}>
                <FileButton id="file-input">
                  <input
                    type="file"
                    name="attachment"
                    id="file-input"
                    onChange={handleAttachment}
                  />
                  <SVGIcon icon="attach" />
                  {t(fileText || 'attach_file')}
                </FileButton>
                <FormButton
                  type="submit"
                  size="small"
                  loading={loading}
                  disabled={!agree}
                >
                  {t('submit')}
                </FormButton>
              </ButtonWrapper>
              <FormConsent
                className="mt-4"
                style={{marginBottom: 10, textAlign: 'center', maxWidth: 500}}
              />
            </FormButtom>
          </div>
        </BottomSection>
      </Form>
    </Wrapper>
  );
};

export default GiftCardForm;
